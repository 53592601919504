import { call, put, takeEvery } from 'redux-saga/effects';
import httpService from '../httpService';
import * as apiUrl from '../apiUrl';
import * as actions from '../../ReduxState/Actions/actionAd';  // One level up

// API call to fetch data, now including region_id and publish_date
async function getDataFromApi(region_id, publish_date) { 
  try {
   
    const response = await httpService.getCallMethod(apiUrl.getSummaryData+'/'+region_id+'/'+publish_date);
    console.log('Summary Data:', response);

  const fullUrl = `${apiUrl.getSummaryData}/${region_id}/${publish_date}`;

  console.log('Summary URL:', fullUrl);


    return response;
  } catch (error) {
    throw error;   
  }
}

function* getSummaryDataSaga(action) {
  try {
   
    const { region_id, publish_date } = action.payload;

    const Summarydata = yield call(getDataFromApi, region_id, publish_date);  
    console.warn('Fetched Data in Saga:', Summarydata);  // Debugging: Log data
    // yield put(actions.getSummayDetailsSuccess(Summarydata));  
    yield put({ type: 'SUMMARY_DATA_SUCCESS', payload: Summarydata });
  } catch (error) {
    console.error('Error in saga:', error);   
    // yield put(actions.getSummayDetailsFailure(error.message));   
    yield put({ type: 'SUMMARY_DATA_FAILURE', payload: error.message });  
}
}


function* FetchSummaryData() {
  yield takeEvery('SUMMARY_DATA_REQUEST', getSummaryDataSaga);  // Change to the correct action type
}

export default FetchSummaryData;