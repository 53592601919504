import {combineReducers} from 'redux';
import adlisting from './adlisting';
import adpositioned from './adpositioned';
import fetchinitialmetadata from './fetchinitialmetadata';
import fetcheadsbypositing from './fetchedadsbyposting';
import privatedbstate from './privatedbstate'; 
import dbpostadscombined from './dbpostadscombined';
import loginuser from './loginuser';
import editslugofad from './editslugofad';
import dataReducer from './dataReducer';
import deleteContentReducer from "./contentPositionDel.js";
import editorialReducer from './PostEditorialdata';
import summaryDataReducer from './summaryDataReducer'; // If directly in `Reducer` directory


const rootReducer = combineReducers({
    adlisting: adlisting, 
    adpositioned: adpositioned,
    fetchinitialmetadata: fetchinitialmetadata,
    fetcheadsbypositing: fetcheadsbypositing,
    privatedbstate: privatedbstate,
    dbpostadscombined: dbpostadscombined,
    loginuser: loginuser,
    editslugofad: editslugofad,
    data: dataReducer,
    editorialReducer,
    deleteContent: deleteContentReducer,
    summaryDataReducer,

})

export default rootReducer; 
