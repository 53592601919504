import { useState, memo, useEffect } from "react";
import { Rnd } from "react-rnd";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiArrowRightCircle } from "react-icons/hi2";
import Select from "react-select";
import * as constant from "../../CONSTANT";

const ContentCard = ({
  childValues,
  reasignPosValues,
  deleteChildDivs,
  pageNumbersArrays,
  afterPageMovedArrUpdate, // Ensure this is passed from the parent
}) => {
  const style = {
    alignItems: "center",
    border: "1px solid #ddd",
    background: "#e8ffe8",
    padding: "10px",
    borderRadius: "4px",
  };

  const PAPER_WIDTH = constant.NEWS_PAPER_WIDTH;
  const GRID_COLUMNS = 6;
  const GRID_WIDTH = PAPER_WIDTH / GRID_COLUMNS;

  // Extract content positions and dimensions from the publish_details array
  const publishDetails = childValues.publish_details?.[0] || {};
  const initialWidth = publishDetails.content_width || GRID_WIDTH * 2;
  const initialHeight = publishDetails.content_height || GRID_WIDTH * 2;
  const initialX = publishDetails.content_position_x || 0;
  const initialY = publishDetails.content_position_y || 0;

  const [pageMovedValue, setPageMovedValue] = useState(null); // Default to null
  const [displayPageMove, setDisplayPageMove] = useState(false); // Initialize state

  const [RND, setRND] = useState({
    width: initialWidth,
    height: initialHeight,
    x: initialX,
    y: initialY,
  });

  const [isVisible, setIsVisible] = useState(true); // Local state for visibility
  const hideCard = () => setIsVisible(false);

  const findNearestSnapLine = (x) => Math.round(x / GRID_WIDTH) * GRID_WIDTH;

  const changePosition = (e, d) => {
    const snappedX = findNearestSnapLine(d.x);
    const newPosition = { x: snappedX, y: d.y };
    setRND((prev) => ({ ...prev, ...newPosition }));

    // Communicate the updated position to parent
    reasignPosValues({
      id: childValues.id,
      content_width: RND.width,
      content_height: RND.height,
      content_position_x: snappedX,
      content_position_y: d.y,
    });
  };

  const selectPageOptChange = (e) => {
    setPageMovedValue(e);
    setDisplayPageMove(false);

    // Update the parent with the changed page number
    const updatedChild = { ...childValues, page_no: e?.value };
    afterPageMovedArrUpdate(updatedChild); // Call parent function
  };

  const activePageOpt = (e) => {
    e.preventDefault();
    setDisplayPageMove((prev) => !prev); // Toggle page move dropdown
  };

  const changeSize = (e, direction, ref, delta, position) => {
    const snappedWidth = findNearestSnapLine(parseInt(ref.style.width, 10));
    const newSize = {
      width: snappedWidth,
      height: parseInt(ref.style.height, 10),
      x: position.x,
      y: position.y,
    };
    setRND(newSize);

    // Communicate the updated size to parent
    reasignPosValues({ id: childValues.id, ...newSize });
  };

  // Communicate initial RND state to parent when component mounts
  useEffect(() => {
    reasignPosValues({
      id: childValues.id,
      content_width: RND.width,
      content_height: RND.height,
      content_position_x: RND.x,
      content_position_y: RND.y,
    });
  }, []);

   if (!isVisible) {
    return null;
  }

  return (
    // <Rnd
    //   bounds="parent"
    //   size={{ width: RND.width, height: RND.height }}
    //   position={{ x: RND.x, y: RND.y }}
    //   onDragStop={changePosition}
    //   onResizeStop={changeSize}
    //   style={style}
    //   minWidth={100}
    //   minHeight={100}
    // >
      <Rnd
        bounds="parent"
        size={{ width: RND.width, height: RND.height }}
        position={{ x: RND.x, y: RND.y }}
        onDrag={(e, d) => {
          // Capture the new position during drag
          const snappedX = findNearestSnapLine(d.x);
          const snappedY = d.y; // Modify this if snapping is needed
          setRND((prev) => ({ ...prev, x: snappedX, y: snappedY }));

          // Communicate the updated position and size to the parent
          reasignPosValues({
            id: childValues.id,
            content_width: RND.width,
            content_height: RND.height,
            content_position_x: snappedX,
            content_position_y: snappedY,
          });
        }}
        onResize={(e, direction, ref, delta, position) => {
          // Update size dynamically during resizing
          const snappedWidth = findNearestSnapLine(parseInt(ref.style.width, 10));
          const snappedHeight = parseInt(ref.style.height, 10);

          setRND((prev) => ({
            ...prev,
            width: snappedWidth,
            height: snappedHeight,
            x: position.x,
            y: position.y,
          }));

          // Communicate size updates to the parent
          reasignPosValues({
            id: childValues.id,
            content_width: snappedWidth,
            content_height: snappedHeight,
            content_position_x: position.x,
            content_position_y: position.y,
          });
        }}
        style={style}
        minWidth={100}
        minHeight={100}
      >

      <div style={{ position: "relative" }}>
        {/* Content Details */}
        <div style={{ padding: "10px" }}>
          <h4>{childValues.title}</h4>
          <p>{childValues.content}</p>
        </div>

        {/* Change Page Section */}
        <div style={{ position: "absolute", top: "-15px", left:"-15px" }}>
          <span
            title="Change page"
            style={{
              cursor: "pointer",
              display: "inline-flex",
              alignItems: "center",
              top: "10px",
            }}
            onClick={activePageOpt}
          >
            <HiArrowRightCircle size={22} color="black" />
          </span>
          {displayPageMove && (
            <span
              style={{
                position: "absolute",
                zIndex: 10,
                background: "white",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                borderRadius: "4px",
              }}
            >
              <Select
                options={pageNumbersArrays}
                value={pageMovedValue}
                onChange={selectPageOptChange}
              />
            </span>
          )}
        </div>

        {/* Remove Button */}
        <span
          title="Remove"
          style={{ position: "absolute", top: "-15px", right: "-20px", cursor: "pointer" }}          onClick={(e) => {setIsVisible(false);
              deleteChildDivs(e, childValues)}}
          // onClick={hideCard} // Hide card on click
        >
          <AiFillCloseCircle size={20} color="black" />
        </span>
      </div>
    </Rnd>
  );
};

export default memo(ContentCard);