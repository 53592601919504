import * as type from '../Constants/adlist';  // Import action types

const initialState = {
    Summarydata: [], // Ensure it is defined
    loading: false,
    error: null,
};

const summaryDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SUMMARY_DATA_REQUEST':
            return { ...state, loading: true };
        case 'SUMMARY_DATA_SUCCESS':
            return { ...state, loading: false, Summarydata: action.payload };
        case 'SUMMARY_DATA_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default summaryDataReducer;
