import {call, put, takeEvery} from 'redux-saga/effects';
import httpService from '../httpService';
import * as apiUrl from '../apiUrl';
// import { filterParamsData } from '../dummyData.js';

async function fetchParamListDataApiCall(paramsLoad){
    const regParams = paramsLoad ? paramsLoad : ''; 
    console.log('regParams', regParams.regionId);
    try {
        const response = await httpService.getCallMethod(apiUrl.fetchFilterParams+'/'+ regParams.regionId+'/'+ regParams.publish_date);
        return response.data;
    } catch (error) {
        throw error; 
    }
}

async function fetchRegionCall(){
    try {
        const response = await httpService.getCallMethod(apiUrl.fetchRegionUrl);
        return response;
    } catch (error) {
            throw error;
    }
}
function* fetchingRegionDatas(){
    try {
        const region = yield call (()=> fetchRegionCall());
        yield put({type: 'FETCH_REGIONS_SUCCESS', regionDatasFromApi : region})
    } catch (error) {
        yield put ({type: 'FETCH_ADS_BY_POST_FAILED', regionDataErrorFromApi : error})
    }
}


function* fetchinitialParamsData(action){
     try {
        const fetchedParamData = yield call(()=>fetchParamListDataApiCall(action?.payload))
        yield put({type : 'FETCH_INITIAL_PARAMS_DATA_SUCESS', fetchedParamData: fetchedParamData})
     } catch (error) {
        yield put({type: 'FETCH_INITIAL_PARAMS_DATA_FAILED', erorInfetchedParamData: error})
     }
}

function* fetchinitialmetadataSaga(){
    yield takeEvery('FETCH_INITIAL_PARAMS_DATA', fetchinitialParamsData)
}

export function* fetchregionlistSaga(){
    yield takeEvery('FETCH_REGIONS',  fetchingRegionDatas)
}
export default fetchinitialmetadataSaga; 