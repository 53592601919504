import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Container, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import TableAdsList from "../SharedComponent/TableAdsList/TableAdsList";
import { ADTYPES_KEY, ADTYPE_TITLE_TABLE } from "../CONSTANT";
import "./AccordianTable.scss";

const AccordianTable = ({regionVal}) => {
  const {
    // eslint-disable-next-line no-unused-vars
    fetchInitialParamLoading,
    fetchMetaDataInitalDataParams,
    // eslint-disable-next-line no-unused-vars
    errorInFetchMetaData,
  } = useSelector((state) => state.fetchinitialmetadata);
  const mapData = [
    {
      key: ADTYPES_KEY?.nap,
      title: ADTYPE_TITLE_TABLE?.nap,
      tableData: [],
    },
    {
      key: ADTYPES_KEY?.reta,
      title: ADTYPE_TITLE_TABLE?.reta,
      tableData: [],
    },
    {
      key: ADTYPES_KEY?.classifieds,
      title: ADTYPE_TITLE_TABLE?.classifieds,
      tableData: [],
    },
  ];
  const [tableData, setTableData] = useState(mapData);

  useEffect(() => {
    setTableData((prev) =>
      prev.map((el) => ({
        ...el,
        tableData:
          el?.key === ADTYPES_KEY?.nap
            ? fetchMetaDataInitalDataParams?.[0]?.[ADTYPES_KEY?.nap]
            : el?.key === ADTYPES_KEY?.reta
              ? fetchMetaDataInitalDataParams?.[0]?.[ADTYPES_KEY?.reta]
              : fetchMetaDataInitalDataParams?.[0]?.[ADTYPES_KEY?.classifieds],
      }))
    );
  }, [fetchMetaDataInitalDataParams]);

  return (
    <div className="accordianTableList">
      <Container>
        <Col>
          <Row>
            <Accordion >
              <h6 style={{fontSize:"larger"}}>Today's advertisement list</h6>
              {tableData?.map((tableItem, i) => {
                return (
                  <Accordion.Item eventKey={String(i)} key={i + tableItem?.key}>
                    <Accordion.Header>{tableItem?.title}</Accordion.Header>
                    <Accordion.Body>
                      <TableAdsList tableData={tableItem} regionVal={regionVal}/>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Row>
        </Col>
      </Container>
    </div>
  );
};

export default AccordianTable;
