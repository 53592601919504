import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Button, Col, Container, Row } from "react-bootstrap";
import Loader from "../SharedComponent/Loader/Loader";
import { fetchAdsListByPosting, fetchParamsListData} from "../ReduxState/Actions/actionAd";
import { fetchDataRequest, getSummayDetails} from "../ReduxState/Actions/actionAd";
import { currentDateAsTmrw } from "../Utils/Utils";
import DatePicker from "react-datepicker";
import "./FilterFetchForm.scss";

export default function FilterFetchForm({ regionVal }) {
  const dispatch = useDispatch();
  const { fetchMetaDataInitalDataParams } = useSelector(
    (state) => state.fetchinitialmetadata
  );
  const { fetcheadsbypositingLoading } = useSelector(
    (state) => state.fetcheadsbypositing
  );


  const { data} = useSelector((state) => state.data);
  // console.log('data :', data)
  
  const [dateValue, setDateValue] = useState(currentDateAsTmrw);
  const [formSubmittingData, setFormSubmittingData] = useState({
    adType: null,
    pageNumber: null,
    position: null,
    editionType: null,
    paperNumber: null,
    dateValue: dateValue?.toISOString().split("T")[0],
  });
  const [dropDownValue, setDropDownValue] = useState({
    adType: [],
    pageNumber: [],
    position: [],
    editionType: [
      { label: "Single", value: "single" },
      { label: "Twin", value: "twin" },
    ],
    paperNumber: [
      { label: "One", value: "one" },
      { label: "Two", value: "two" },
    ],
  });
  const [formErrorHelper, setFormErrorHelper] = useState({});

  const changeDateValue = (date) => {
    setDateValue(date);
    const formattedDate = date.toISOString().split("T")[0]; // Extract date part
    setFormSubmittingData((prev) => ({
      ...prev,
      dateValue: formattedDate,
    }));
    setFormErrorHelper((prev) => ({
      ...prev,
      [`dateValue`]: null,
    }));
  };

  const mapLabelValue = (arrayData) => {
    return arrayData?.map((el) => ({
      label: el?.name || el?.page_name,
      value: el?.id,
    }));
  };

  const handleSelectDropDownChange = (name, e) => {
    setFormSubmittingData((prev) => ({
      ...prev,
      [name]: e,
    }));
    //common👇if allFields
    setFormErrorHelper((prev) => ({
      ...prev,
      [name]: null,
    }));
    //👇HardCodedIncase coz change is not for all it has to happen in edition for paperNumber
    setFormErrorHelper((prev) => ({
      ...prev,
      [`paperNumber`]: null,
    }));
  };
const submitHandler = (e) => {
  e.preventDefault();
  const publishDate = dateValue.toISOString().split('T')[0];


  dispatch(
    fetchParamsListData({
      // ...formSubmittingData,
      regionId: regionVal?.value,
      publish_date: publishDate,
    })
  );

  dispatch(
    fetchAdsListByPosting({
      ...formSubmittingData,
      regionId: regionVal?.value,
      // publish_date: publishDate,
    })
  );

  dispatch(
    fetchDataRequest({
      region_id: regionVal?.value,
      publish_date: publishDate,
    })
  );

  dispatch(
    getSummayDetails({
      region_id: regionVal?.value,
      publish_date: publishDate,
    })
  );
};

  useEffect(() => {
    const adTypeTransform = mapLabelValue(
      fetchMetaDataInitalDataParams?.[0]?.["ad_type"]
    );
    const pageNoTransform = mapLabelValue(
      fetchMetaDataInitalDataParams?.[0]?.["page_number"]
    );
    const positionTransform = mapLabelValue(
      fetchMetaDataInitalDataParams?.[0]?.["position"]
    );
    setDropDownValue((prev) => ({
      ...prev,
      adType: adTypeTransform,
      pageNumber: pageNoTransform,
      position: positionTransform,
    }));
  }, [fetchMetaDataInitalDataParams]);

  useEffect(() => {
    setFormSubmittingData((prev) => ({
      ...prev,
      editionType: { label: "Single", value: "single" },
      paperNumber: { label: "One", value: "one" },
    }));
  }, []);

  useEffect(() => {
    //setting paper to one , if the user already selected two and again selecting edition one.
    if (formSubmittingData?.editionType?.value === "single") {
      if (formSubmittingData?.paperNumber?.value === "two") {
        setFormSubmittingData((prev) => ({
          ...prev,
          editionType: { label: "Single", value: "single" },
          paperNumber: { label: "One", value: "one" },
        }));
      }
    }
  }, [formSubmittingData?.editionType, formSubmittingData?.paperNumber]);

  return (
    <div>
      <form onSubmit={submitHandler}>
        <div className="fetchForm">
          <Container>
            <h6 style={{fontSize:"larger"}}>Filter Ads</h6>
            <Col className="wrapper">
              <Row xl={3} lg={3} md={1} sm={1} xs={1}>
                {/* <Col>
                  <div className="selectDrop adTypeDrop">
                    <span className="label">Ad Type</span>
                    <Select
                      name="adType"
                      options={dropDownValue?.adType}
                      isSearchable={true}
                      placeholder={
                        <div>
                          <i className="fa fa-search"></i> Search Ad type...
                        </div>
                      }
                      onChange={(e) => handleSelectDropDownChange("adType", e)}
                      value={formSubmittingData?.adType}
                    />
                  </div>
                </Col> */}
                <Col>
                  <div className="selectDrop pageNoDrop">
                    <span className="label">Page No</span>
                    <Select
                      name="adType"
                      options={dropDownValue?.pageNumber}
                      isSearchable={true}
                      placeholder={
                        <div>
                          <i className="fa fa-search"></i> Search page number...
                        </div>
                      }
                      onChange={(e) =>
                        handleSelectDropDownChange("pageNumber", e)
                      }
                      value={formSubmittingData?.pageNumber}
                    />
                    {formErrorHelper?.pageNumber && (
                      <span className="errorField">
                        {formErrorHelper?.pageNumber}
                      </span>
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="selectDrop  positionDrop">
                    <span className="label">Position</span>
                    <Select
                      name="position"
                      options={dropDownValue?.position}
                      isSearchable={true}
                      placeholder={
                        <div>
                          <i className="fa fa-search"></i> Search position...
                        </div>
                      }
                      onChange={(e) =>
                        handleSelectDropDownChange("position", e)
                      }
                      value={formSubmittingData?.position}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="selectDrop datePickerDrop">
                    <span className="label">Date </span>
                    <DatePicker
                      selected={dateValue}
                      onChange={changeDateValue}
                      showIcon
                      id={"filterDate"}
                      dateFormat="dd/MM/yyyy"
                    />
                    {formErrorHelper?.dateValue && (
                      <span className="errorField">
                        {formErrorHelper?.dateValue}
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row xl={3} lg={3} md={1} sm={1} xs={1}>
                <Col>
                  <div className="selectDrop editionTypeDrop">
                    <span className="label">Edition Type</span>
                    <Select
                      name="editionType"
                      options={dropDownValue?.editionType}
                      // placeholder={<div><i className="fa fa-search"></i> Search position...</div>}
                      onChange={(e) =>
                        handleSelectDropDownChange("editionType", e)
                      }
                      value={formSubmittingData?.editionType}
                      placeholder={"Edition type. i.e: Single, Twin"}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="selectDrop paperNumberDrop">
                    <span className="label">Paper Number</span>
                    <Select
                      name="paperNumber"
                      options={dropDownValue?.paperNumber}
                      // placeholder={<div><i className="fa fa-search"></i> Search position...</div>}
                      onChange={(e) =>
                        handleSelectDropDownChange("paperNumber", e)
                      }
                      value={formSubmittingData?.paperNumber}
                      placeholder={"PaperNumber. i.e: One, Two"}
                      isDisabled={
                        formSubmittingData?.editionType?.value === "single" ||
                        !formSubmittingData?.editionType
                      }
                    />
                    {formErrorHelper?.paperNumber && (
                      <span className="errorField">
                        {formErrorHelper?.paperNumber}
                      </span>
                    )}
                  </div>
                </Col>
                <Col>
                <div className="fetchSub"> 
                {fetcheadsbypositingLoading  && data ?(
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <Button type="submit" >Fetch Ads</Button>
                )}
              </div></Col>
              </Row>
            </Col>
          </Container>
        </div>
      </form>
    </div>
  );
}
