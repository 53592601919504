import { useRef, useEffect, useState, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-select";
import ModalPopUp from "../SharedComponent/ModalPopUp/ModalPopUp";
import SliderThumb from "../SharedComponent/SliderThumb/SliderThumb";
import CalenderPick from "../SharedComponent/CalenderPick/CalenderPick";
import PrintContent from "../SharedComponent/PrintContent/PrintContent";
import PageCounter from "../SharedComponent/PageCounter/PageCounter";
import {
  sliceOneAdFromPositionerSection,
  DeleteSelectedContentcard,
  submitDbFinal,
  submitDbCleanUp,
  fetchParamsListData,
  fetchDataRequest,
  postEditorialDetailsRequest,
} from "../ReduxState/Actions/actionAd";
import * as constant from "../CONSTANT";
import {
  adsStructure,
  contentStructure,
  defInitialIncrCount,
  editionTypeOption,
  pageNumberArray,
  paperNumberOption,
  templateOption,
  contentpaperNumberOption,
} from "../dataOption";
import "./NewsPaperContentGrid.scss";
import DraggableCard from "../SharedComponent/DraggableCard/DraggableCard";
import ContentCard from "../SharedComponent/ContentCard/ContentCard";
import Draggable from "react-draggable";
import React from 'react'; // Ensure this is present
import { ResizableBox } from "react-resizable";
import html2canvas from 'html2canvas';
import { configure } from "@testing-library/react";


const PAPER_WIDTH = constant.NEWS_PAPER_WIDTH;
const PAPER_HEIGHT = constant.NEWS_PAPER_HEIGHT;

const PAPER_WIDTH_CM = constant.NEWS_PAPER_WIDTH_CM;
const PAPER_HEIGHT_CM = constant.NEWS_PAPER_HEIGHT_CM;

// console.log('PAPER_WIDTH_CM' , PAPER_WIDTH_CM);
// console.log('PAPER_HEIGHT_CM' , PAPER_HEIGHT_CM);

const pageStyleOption = `
@page {
  size: ${PAPER_WIDTH}px ${PAPER_HEIGHT}px;
  margin: 0;
}
@media print {
  body {
    margin: 0;
    padding: 0;
  }
}
`;

const NewsPaperContentGrid = ({ regionVal }) => {
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const metaDataAdPositioned = useSelector(
    (state) => state.dbpostadscombined.adPositionedWithGrid
  );
  const { fetchMetaDataInitalDataParams } = useSelector(
    (state) => state.fetchinitialmetadata
  );
  // console.log("metaDataAdPositioned",metaDataAdPositioned)
  const { data } = useSelector((state) => state.data);
  // console.log("data", data);
  const { Summarydata, loading, error } = useSelector((state) => state.summaryDataReducer);
    // console.log('Summarydata', Summarydata)
  const { submitAllAdsLoading, submitAllAdsSuccess, submitAllAdsError } =
    useSelector((state) => state.dbpostadscombined);
  const [draggableAdsPos, setDraggableAdsPos] = useState();
  const [dropDownTypeCriteria, setDropDownTypeCriteria] = useState({
    template: null,
    edition: null,
    page: null,
    paperNumber: null,
    pageNumber: null,
  });
  const [draggableContentPos, setDraggableContentPos] = useState();
  const ContentList = draggableContentPos; // Alias for draggableContentPos
  const [contentdropDownTypeCriteria, setContentDropDownTypeCriteria] = useState({
    template: null,
    edition: null,
    page: null,
    paperNumber: null,
    pageNumber: null,
  });
  const [spaceAllocated, setSpaceAllocated] = useState({
    occupied: "",
    remaining: "",
  });
  const [pageNumberOptions, setPageNumberOptions] = useState([]);
  const [contentpageNumberOptions, setContentPageNumberOptions] = useState([]);
  const [bookMarkOption, setBookMarkOption] = useState([]);
  const [bookMarkValue, setBookMarkValue] = useState({});
  const [initialIncrCount, setInitialIncrCount] = useState(defInitialIncrCount);
  const [pageIncrCount, setPageIncrCount] = useState(initialIncrCount);
  const [pageNumbersArrays, setPageNumbersArray] = useState(pageNumberArray);
  const defTemplateIfNoValue = templateOption[3];
  const defPageIfNoValue = pageNumbersArrays[0];
  const defEditionIfNoValue = editionTypeOption[0];
  const defPaperIFNoValue = paperNumberOption[0];
  const defContentPaperIFNoValue = contentpaperNumberOption[0];
  const [datePostPoner, setDatePostPoner] = useState();
  const [toast, setToast] = useState(false);
  const [userClickedSlider, setUserClickedSlider] = useState(false);
  const [optionTemplate, setOptionTemplate] = useState([]);

  /**
   * check the ads having proper width and height
   * @param {Array<{objects}>} adsArry - accepts array of on objects , in this case draggableAdss
   * @returns {Boolean} - wheather the ads having proper height and width or not
   */
  // const mimicAdsFunc = (adsArry) => {
  //   //in order to show blank slides even the ads not present
  //   const valueBoolean = adsArry?.some((ad) => ad?.width && ad?.height);
  //   return !valueBoolean;
  // };

  const mimicAdsFunc = (adsArry, contentArry) => {
    // const adsValid = adsArry?.some((ad) => ad?.width && ad?.height);
    const valueBoolean = adsArry?.some((ad) => ad?.width && ad?.height);
    const contentValid = contentArry?.some((content) => content?.content_width && content?.content_height);
    return !(valueBoolean && contentValid);
  };
  


 const { editorialDetails } = useSelector((state) => state.editorialReducer);
  useEffect(() => {
    if (data?.length) {
      const transformedContent = data.map((item) => ({
        id: item.id || 0,
        title: item.title,
        content: item.content,
        district_id: item.district_id,
        district_name: item.district_name,
        category_tag_id: item.category_tag_id,
        category_tag_name: item.category_tag_name,
        page_no: item.page_no,
        content_position_x: 0, // Default X position
        content_position_y: 0, // Default Y position
        image_list: item.image_list,
        no_of_cm: item.no_of_cm,
        no_of_column: item.no_of_column,
        no_of_lines: item.no_of_lines,
        publish_dates: item.publish_dates,
        publish_details: item.publish_details,
        editorial_details: item.publish_details, // Pass publish details for nested rendering
      }));
  
      setDraggableContentPos(transformedContent);
    }
  }, [data]);
  const bookMarkChange = (val, key) => setBookMarkValue(val);

  const gridPositionValues = useCallback((value) => {
    // setting each element with theri grid positions
    if (value?.name) {
      setDraggableAdsPos((prev) =>
        prev?.map((el) => {
          console.log('gridPositionValues:', el)
          if (el?.id === value?.name) {
            return {
              ...el,
              gridX: value?.x,
              gridY: value?.y,
            };
          } else {
            return {
              ...el,
            };
          }
        })
      );
    }
  }, []);

  const contentgridPositionValues = useCallback((updatedContent) => {
    setDraggableContentPos((prev) =>
      prev.map((el) =>
        el.id === updatedContent.id
          ? {
              ...el,
              ...updatedContent,
              page_no: updatedContent.page_no || el.page_no,
            }
          : el
      )
    );
  }, []);
  
  
  const pageCountIncrDecrHandle = useCallback((e, opr) => {
    e.preventDefault();
    if (opr === "minus") {
      setPageIncrCount((prev) =>
        pageIncrCount <= defInitialIncrCount ? defInitialIncrCount : prev - 2
      );

      if (mimicAdsFunc(draggableAdsPos)) {
        // mimicAds
        const _transFormArray = [...draggableAdsPos];
        _transFormArray?.splice(-2, -2);
        setDraggableAdsPos(_transFormArray);
      }
    }
    if (opr === "plus") {
      setPageIncrCount((prev) => prev + 2);

      if (mimicAdsFunc(draggableAdsPos)) {
        // mimicAds
        const _transFormArray = [...draggableAdsPos];
        _transFormArray?.splice(
          draggableAdsPos?.length,
          0,
          { ...adsStructure, page: Number(draggableAdsPos?.length + 1) },
          { ...adsStructure, page: Number(draggableAdsPos?.length + 2) }
        );
        setDraggableAdsPos(_transFormArray);
      }
    }
  }, [draggableAdsPos, pageIncrCount]);



  // const ContentpageCountIncrDecrHandle = useCallback((e, opr) => {
  //   e.preventDefault();
  //   if (opr === "minus") {
  //     setPageIncrCount((prev) =>
  //       pageIncrCount <= defInitialIncrCount ? defInitialIncrCount : prev - 2
  //     );

  //     if (mimicAdsFunc(draggableContentPos)) {
  //       // mimicAds
  //       const _transFormArray = [...draggableContentPos];
  //       _transFormArray?.splice(-2, -2);
  //       setDraggableContentPos(_transFormArray);
  //     }
  //   }
  //   if (opr === "plus") {
  //     setPageIncrCount((prev) => prev + 2);

  //     if (mimicAdsFunc(draggableContentPos)) {
  //       // mimicAds
  //       const _transFormArray = [...draggableContentPos];
  //       _transFormArray?.splice(
  //         draggableContentPos?.length,
  //         0,
  //         { ...contentStructure, page: Number(draggableContentPos?.length + 1) },
  //         { ...contentStructure, page: Number(draggableContentPos?.length + 2) }
  //       );
  //       setDraggableContentPos(_transFormArray);
  //     }
  //   }
  // }, [draggableContentPos, pageIncrCount]);


  const openCloseToast = () => setToast((prev) => !prev);

  const modalOkClick = (e) => {
    e.preventDefault();
    dispatch(submitDbCleanUp()); // db
    dispatch({ type: "RESET_DATA" });
    setDraggableAdsPos([]);
    setDraggableContentPos([]);
    // console.log("Content cards cleared:", draggableContentPos);
    dispatch(fetchDataRequest(regionVal?.value));
    setInitialIncrCount(defInitialIncrCount); // pageIncrReset
    dispatch(fetchParamsListData(regionVal?.value)); //->to call api again to display updated tableData.
    openCloseToast();
  };

  const adsInsideNewsPaper = (arrList) => {
    const filterAccordingly = arrList?.filter(
      (el) => el?.page === dropDownTypeCriteria?.page?.value
    );
    const defLoadPageValue = "1"; //default pageNo should be assigned for 1 if the new ads not yet positoned
    const filterInSpecialCase = arrList?.filter(
      () => defLoadPageValue === dropDownTypeCriteria?.page?.value
    );
    const adsGotAssignedToPage = !!arrList?.find((el) => el?.page);
    return adsGotAssignedToPage ? filterAccordingly : filterInSpecialCase;
  };
 
  // const contentInsideNewsPaper = (ContentList) => {
  //   const currentPageValue = dropDownTypeCriteria?.page?.value;
  //   if (!Array.isArray(ContentList)) {
  //     // console.error("Expected ContentList to be an array but got:", ContentList);
  //     return [];
  //   }
  //   // Filter only if ContentList is a valid array
  //   return ContentList.filter((el) => el?.page_no === currentPageValue);
  // };

  const contentInsideNewsPaper = (ContentList) => {
    const currentPageValue = contentdropDownTypeCriteria?.page?.value;
    if (!Array.isArray(ContentList)) {
      console.error("Expected ContentList to be an array.");
      return [];
    }
    const filteredContent = ContentList.filter(
      (el) => el?.page_no === currentPageValue
    );
    return filteredContent;
  };
  
  const dateValueFetched = (e) => {
    setDatePostPoner(e);
    const inputDate = new Date(e);
    const formattedDate = inputDate.toISOString().split("T")[0];
    setDraggableAdsPos((prev) =>
      prev?.map((el) => {
        if (el?.page === dropDownTypeCriteria?.page?.value) {
          return {
            ...el,
            _new_publish_date: formattedDate,
          };
        } else {
          return { ...el };
        }
      })
    );
  };

 const contentPostponeDate = (e) => {
    const inputDate = new Date(e);
    const formattedDate = inputDate.toISOString().split("T")[0];
    // Update the datePostPoner state
    setDatePostPoner(inputDate);
    // Update the draggableContentPos state
    setDraggableContentPos((prev) => {
      const updatedPos = prev?.map((el) => {
        if (el?.page_no === contentdropDownTypeCriteria?.page?.value) {
          return {
            ...el,
            _new_publish_date: formattedDate, // Update the postpone date
          };
        }
        return el; // Keep other cards unchanged
      });
      return updatedPos; // Ensure the updated array is returned
    });
  };

  const actionAdMovedToOtherPage = useCallback((arr) => {
    const _draggableAdsPos = [...draggableAdsPos];
    const index = _draggableAdsPos?.findIndex((item) => item?.id === arr?.id);
    _draggableAdsPos?.splice(index, 1, arr);
    setDraggableAdsPos(_draggableAdsPos);
  }, [draggableAdsPos]);
  
  const contentactionMovedToOtherPage = useCallback((updatedContent) => {
    setDraggableContentPos((prev) =>
      prev.map((el) =>
        el.id === updatedContent.id
          ? {
              ...el,
              page_no: updatedContent.page_no || el.page_no, // Keep `page_no` intact
            }
          : el
      )
    );
  }, []);
  
  
  const submitActionDraggablePost = (e) => {
    e.preventDefault();
    openCloseToast();
    const condnCheckPostPoned = (originDate, postPoneDate) => {
      return JSON.stringify(originDate) === JSON.stringify(postPoneDate)
        ? false
        : true;
    };
    const arrayPositionedByUser = draggableAdsPos?.map((el) => {
      console.log('draggableAdsPos el', el)
      return {
        id: el?.id || 0,
        adv_template_id: el?.adv_template_id || dropDownTypeCriteria?.template?.value || 0,
        adv_template_name: el?.adv_template_name || dropDownTypeCriteria?.template?.label,
        ad_type_id: el?.ad_type_id || 0,
        ad_type: el?.ad_type,
        position_id: el?.position_id || 0,
        position_name: el?.position_name,
        size: el?.size,
        ad_template:
          el?.ad_template === templateOption[3].value ? "" : el?.ad_template,
        dummy_chart_position_x: el?.gridX,
        dummy_chart_position_y: el?.gridY,
        page_name: el?.page_name,
        page_id: el?.page_id || 0,
        edition_type: dropDownTypeCriteria?.edition?.value,
        paper_number: dropDownTypeCriteria?.paperNumber?.value,
        page_count: pageIncrCount,
        page: el?.page,
        original_publish_date: el?.publish_date,
        publish_date: el?._new_publish_date,
        postponed_ad: condnCheckPostPoned(
          el?.publish_date,
          el?._new_publish_date
        ),
      };
    });
    dispatch(submitDbFinal(arrayPositionedByUser)); //db
    const contentarrayPositionedByUser = draggableContentPos?.map((el) => {
      // const editorialIds = el.publish_details.map(detail => detail.editorial_id);
      return {
        id: el?.id || 0,
        editorial_id: el?.publish_details?.[0]?.editorial_id || 0,  // Get the first editorial_id from the array
        title: el?.title,
        // content: el?.content,
        content_width: el?.content_width, 
        content_height: el?.content_height,  
        content_position_x: el?.content_position_x,  
        content_position_y: el?.content_position_y,  
        page_no: el?.page_no,
        template_id: el?.template_id || contentdropDownTypeCriteria?.template?.value || 0,
        template_name: el?.template_name || contentdropDownTypeCriteria?.template?.label || "",
        original_publish_date: el?.publish_details?.[0]?.publish_date,
        publish_date: el?._new_publish_date,
        // publish_date: el?.publish_details?.[0]?.publish_date ,  
        postponed_ad: condnCheckPostPoned(
          el?.publish_date,
          el?._new_publish_date
        ),
      };
    });

    console.log('contentarrayPositionedByUser :', contentarrayPositionedByUser)
    dispatch(postEditorialDetailsRequest(contentarrayPositionedByUser)); //db
  };

  // const dropDownOnChangeEventhandler = (e, targetName) => {
  //   setDropDownTypeCriteria((prev) => ({
  //     ...prev,
  //     [`${targetName}`]: e,
  //   }));

  //   if (targetName === "template") {
    //   const _draggableAdsPos = [...draggableAdsPos];
    //   const _transformedList = _draggableAdsPos?.map((adList) => {
    //     if (adList?.page === dropDownTypeCriteria?.page?.value) {
    //       return {
    //         ...adList,
    //         adv_template_id: e?.value,
    //         adv_template_name: e?.label,
    //       };
    //     } else {
    //       return { ...adList };
    //     }
    //   });
    //   setDraggableAdsPos(_transformedList);
    // }
  // };

  const dropDownOnChangeEventhandler = (e, targetName) => {
    setDropDownTypeCriteria((prev) => ({
      ...prev,
      [`${targetName}`]: e,
    }));
  
    if (targetName === "template" && e) {
      setDraggableAdsPos((prev) =>
        prev?.map((ad) =>
          ad.page === dropDownTypeCriteria?.page?.value
            ? {
                ...ad,
                adv_template_id: e.value,
                adv_template_name: e.label,
              }
            : ad
        )
      );
    }

    setContentDropDownTypeCriteria((prev) => ({
      ...prev,
      [`${targetName}`]: e,
    }));
  
    if (targetName === "template" && e) {
      console.log('setContentDropDownTypeCriteria:', e);
      console.log('targetName:', targetName);
      setDraggableContentPos((prev) =>
        prev?.map((news) =>
          news.page_no === contentdropDownTypeCriteria?.page?.value
            ? {
                ...news,
                  template_id: e.value,
                  template_name: e.label,
              }
            : news
        )
      );
    }

    if (targetName === "page") {
      const contentPageNumber = draggableContentPos?.find(
        (el) => el.page_no === e.value
      );

      // Update `contentdropDownTypeCriteria` specifically
      setContentDropDownTypeCriteria((prev) => ({
        ...prev,
        [targetName]: contentPageNumber
          ? { value: contentPageNumber.page_no, label: `${contentPageNumber.page_no}` }
          : e, // Fallback to selected page
      }));
    }

  };
  
  const deleteTheChildrenInDrags = (e, childValues) => {
    e.preventDefault();
    const payload = {
      deletingId: childValues?.id,
      updatingValues: draggableAdsPos,
    };
    dispatch(sliceOneAdFromPositionerSection(payload)); //db
  };

  const [deletedId, setDeletedId] = useState(null);
  const contentcardDelete = (e, childValues) => {
    e.preventDefault();
    setDraggableContentPos((prev) => prev.filter((item) => item.id !== childValues.id));
    // If Redux is used // Track deleted ID
    setDeletedId(childValues.id);
    const payload = {
      deletingId: childValues?.publish_details?.[0]?.editorial_id,
      updatingValues: draggableContentPos,
    };
    dispatch(DeleteSelectedContentcard(payload));
  };

  useEffect(() => {
    if (deletedId !== null) {
      // Synchronize with other states if needed
      setDraggableContentPos((prev) => prev.filter((item) => item.id !== deletedId));
      setDeletedId(null); // Reset after use
    }
  }, [deletedId]);
  
  const selectionDoneFromSlider = useCallback((val = true) => {
    setUserClickedSlider(val);
  }, []);

  const callBackDropdownchangeHandler = useCallback(
    (fromSlider) => {
      const { page, template } = fromSlider;
      setDropDownTypeCriteria((prev) => ({
        ...prev,
        page,
        template:
          template ??
          optionTemplate?.find((option) => option?.template_default_all),
      }));

      setContentDropDownTypeCriteria((prev) => ({
        ...prev,
        page,
        template:
          template ??
          optionTemplate?.find((option) => option?.template_default_all),
      }));

    },
    [optionTemplate]
  );


  // useEffect(() => {
  //   setDraggableAdsPos(metaDataAdPositioned);
  // }, [metaDataAdPositioned]);

  useEffect(() => {
    if (metaDataAdPositioned || data) {
      setDraggableAdsPos(metaDataAdPositioned);
  
      // Safely set draggableContentPos only if data is an array
      if (Array.isArray(data)) {
        setDraggableContentPos(data);
      } else {
        console.warn("Data is not an array. Resetting draggableContentPos to an empty array.");
        setDraggableContentPos([]);
      }
    }
  }, [metaDataAdPositioned, data]);
  

  useEffect(() => {
    let parentContainerArea = 0;
    let adsoccupiedSpace = 0;
    let parentContainerAreaCm = 0;
    let totalLines = 0;
    let contentoccupiedSpace = 0;

    if (parentRef.current) {
        const containerWidth = PAPER_WIDTH;
        const containerHeight = PAPER_HEIGHT;
        parentContainerArea = containerWidth * containerHeight;

        const containerWidthCm = PAPER_WIDTH_CM; // Width in cm
        const containerHeightCm = PAPER_HEIGHT_CM; // Height in cm
        parentContainerAreaCm = containerWidthCm * containerHeightCm;
        totalLines = parentContainerAreaCm / 3; // Assuming 3 cm per line
    }

    // Calculate Ads Occupied Space
    draggableAdsPos
        ?.filter((el) => el?.page === dropDownTypeCriteria?.page?.value)
        ?.forEach((el) => {
            const area = +el?.height * +el?.width;
            adsoccupiedSpace += area;
        });

    // Calculate Content Occupied Space
    draggableContentPos
        ?.filter((el) => el?.page_no === contentdropDownTypeCriteria?.page?.value)
        ?.forEach((el) => {
            console.log('el:', el); // Log the content details for debugging
            const contentWidth = el?.publish_details?.[0]?.content_width || 0;
            const contentHeight = el?.publish_details?.[0]?.content_height || 0;
            const area = contentWidth * contentHeight; // Calculate area in cm²
            contentoccupiedSpace += area;
        });

    // Calculate percentages and remaining space
    let remainingPercentage = 0;
    let remainingCm = 0;
    let occupiedLines = 0;
    let AdsoccupiedColumns = 0;
    let NewsoccupiedColumns = 0;
    let totalColumns = 0;
    const adsoccupiedPercentage = (adsoccupiedSpace / parentContainerArea) * 100;
    // const remainingPercentage = 100 - adsoccupiedPercentage;
    const adsoccupiedCm = (adsoccupiedPercentage / 100) * parentContainerAreaCm;
    // const remainingCm = parentContainerAreaCm - adsoccupiedCm;
    // occupiedLines = adsoccupiedCm / 3;
    totalColumns = PAPER_WIDTH_CM * PAPER_HEIGHT_CM /286;
    // console.log(totalColumns)
    // AdsoccupiedColumns = adsoccupiedCm /286;
    // AdsoccupiedColumns = Math.ceil(AdsoccupiedColumns);

    const remainingLines = totalLines - occupiedLines;
    const contentoccupiedPercentage = (contentoccupiedSpace / parentContainerArea) * 100;
    const contentremainingPercentage = 100 - adsoccupiedPercentage - contentoccupiedPercentage;
    const contentoccupiedCm = (contentoccupiedPercentage / 100) * parentContainerAreaCm;
    const contentremainingCm = parentContainerAreaCm - adsoccupiedCm - contentoccupiedCm;
    const contentoccupiedLines = contentoccupiedCm / 3;
    const contentremainingLines = totalLines - occupiedLines - contentoccupiedLines;
        if(adsoccupiedPercentage){
            remainingPercentage = 100 - adsoccupiedPercentage;
        }else{
            remainingPercentage = 100 - adsoccupiedPercentage - contentoccupiedPercentage;
        }
        if(remainingCm){
          remainingCm = parentContainerAreaCm - adsoccupiedCm;
        }else{
          remainingCm = parentContainerAreaCm - adsoccupiedCm - contentoccupiedCm;
        }
        if(occupiedLines){  
          occupiedLines = adsoccupiedCm / 3;
        }else{ 
          occupiedLines = contentoccupiedCm / 3;
        }

        AdsoccupiedColumns = adsoccupiedCm /286;
        NewsoccupiedColumns = contentoccupiedCm / 286;
        // const remainingColumns = totalColumns - AdsoccupiedColumns - NewsoccupiedColumns;

        const truncatedAdsColumns = Math.floor(AdsoccupiedColumns * 100) / 100; 
        const truncatedNewsColumns = Math.floor(NewsoccupiedColumns * 100) / 100; 
      
        // Dynamically calculate remainingColumns to ensure the total is exactly 6
        const remainingColumns = parseFloat((6 - (truncatedAdsColumns + truncatedNewsColumns)).toFixed(2)); 

    setSpaceAllocated((prev) => ({
        ...prev,
        adsoccupied: Math.round(adsoccupiedPercentage),
        remaining: Math.round(remainingPercentage),
        adsoccupied_cm: Math.round(adsoccupiedCm),
        remaining_cm: Math.round(remainingCm),
        adsoccupied_Lines: Math.round(occupiedLines),
        remaining_Lines: Math.round(remainingLines),

        contentoccupied: Math.round(contentoccupiedPercentage),
        contentremaining: Math.round(contentremainingPercentage),
        contentoccupied_cm: Math.round(contentoccupiedCm),
        contentremaining_cm: Math.round(contentremainingCm),
        contentoccupied_Lines: Math.round(contentoccupiedLines),
        contentremaining_Lines: Math.round(contentremainingLines),
        remainingOverallLines: Math.round(remainingLines),

        // AdsoccupiedColumns: Math.floor(AdsoccupiedColumns * 100) / 100,
        // NewsoccupiedColumns: Math.floor(NewsoccupiedColumns * 100) / 100,
        // remainingColumns:  Math.floor(remainingColumns * 100) / 100,

        AdsoccupiedColumns: truncatedAdsColumns,
        NewsoccupiedColumns: truncatedNewsColumns,
        remainingColumns: remainingColumns,


        // advertisement_no_of_cm: Math.round(advertisement_no_of_cm),
    }));
  }, [draggableAdsPos, draggableContentPos, pageNumbersArrays, dropDownTypeCriteria?.page?.value, contentdropDownTypeCriteria?.page?.value]);
  
  
  
  useEffect(() => {
    const pageNoTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "page_number"
    ]?.map((el) => ({
      label: el?.page_name,
      value: el?.id,
    }));

    const bookMarkTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "page_place_holder"
    ]?.map((el) => ({
      ...el,
      label: el?.name,
      value: el?.id,
    }));
    const optionTemplateTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "adv_template"
    ]?.map((el) => ({
      ...el,
      label: el?.template_name,
      value: el?.template_id,
    }));
    setOptionTemplate(optionTemplateTransform);
    setPageNumberOptions(pageNoTransform);
     setContentPageNumberOptions(pageNoTransform);
    setBookMarkOption(bookMarkTransform);
  }, [fetchMetaDataInitalDataParams]);


  // useEffect(() => {
  //   const pageNoTransform = data?.[0]?.[
  //     "page_number"
  //   ]?.map((el) => ({
  //     label: el?.page,
  //     value: el?.id,
  //   }));

  
  //   const optionTemplateTransform = data?.[0]?.[
  //     "news_template"
  //   ]?.map((el) => ({
  //     ...el,
  //     label: el?.template_name,
  //     value: el?.template_id,
  //   }));
  //   setOptionTemplate(optionTemplateTransform);
  //   setPageNumberOptions(pageNoTransform);
  //    setContentPageNumberOptions(pageNoTransform);
  // }, [data]);

  useEffect(() => {
    if (dropDownTypeCriteria?.edition?.value === "single") {
      setDropDownTypeCriteria((prev) => ({
        ...prev,
        [`paperNumber`]: paperNumberOption[0],
      }));
    }
  }, [dropDownTypeCriteria?.edition]);

// COntent//
  useEffect(() => {
    if (dropDownTypeCriteria?.edition?.value === "single") {
      setDropDownTypeCriteria((prev) => ({
        ...prev,
        [`paperNumber`]: contentpaperNumberOption[0],
      }));
    }
  }, [dropDownTypeCriteria?.edition]);

  useEffect(() => {
    const templatePop = templateOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.ad_template === el?.value
      );
      return match;
    });
    const pagePop = pageNumbersArrays?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.page === el?.value
      );
      return match;
    });

    const editionPop = editionTypeOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.edition_type === el?.value
      );
      return match;
    });

    const paperPop = paperNumberOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.paper_number === el?.value
      );
      return match;
    });

    const pageNumberPop = pageNumberOptions?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.page_id === el?.value
      );
      return match;
    });

    setDropDownTypeCriteria((prev) => ({
      ...prev,
      edition: editionPop ? editionPop : defEditionIfNoValue,
      page: pagePop ? pagePop : defPageIfNoValue,
      paperNumber: paperPop ? paperPop : defPaperIFNoValue,
      template: templatePop ? templatePop : defTemplateIfNoValue,
      pageNumber: pageNumberPop ? pageNumberPop : null,
    }));
  }, [
    defEditionIfNoValue,
    defPageIfNoValue,
    defPaperIFNoValue,
    defTemplateIfNoValue,
    metaDataAdPositioned,
    pageNumberOptions,
    pageNumbersArrays,
  ]);


  // content //

useEffect(() => {
  console.log('object:', templateOption)

    const newstemplatePop = templateOption?.find((el) => {
      const match = data?.find(
        (item) => item?.news_template === el?.value
      );
      return match;
    });
    const pagePop = pageNumbersArrays?.find((el) => {
      const match = Array(data)?.find(  (item) => item?.page_no === el?.value);
      return match;
    });

    const paperPop = contentpaperNumberOption?.find((el) => {
      const match = Array(data)?.find(
        (item) => item?.paper_no === el?.value
      );
      return match;
    });

    const pageNumberPop = contentpageNumberOptions?.find((el) => {
      const match = Array(data)?.find(
        (item) => item?.page_id === el?.value
      );
      return match;
    });

    setContentDropDownTypeCriteria((prev) => ({
      ...prev,
      newstemplate: newstemplatePop ? newstemplatePop : defTemplateIfNoValue,
      page: pagePop ? pagePop : defPageIfNoValue,
      paperNumber: paperPop ? paperPop : defPaperIFNoValue,
      pageNumber: pageNumberPop ? pageNumberPop : null,
    }));
  }, [
    defEditionIfNoValue,
    defPageIfNoValue,
    defPaperIFNoValue,
    defTemplateIfNoValue,
    data,
    contentpageNumberOptions,
    pageNumbersArrays,
  ]);

  

  useEffect(() => {
    if (draggableAdsPos?.length) {
      const adTime = draggableAdsPos?.find(
        (el) => el?.page === dropDownTypeCriteria?.page?.value
      )?._new_publish_date;
      setDatePostPoner(adTime ? new Date(adTime) : new Date());
    }
  }, [dropDownTypeCriteria?.page?.value, draggableAdsPos]);

  useEffect(() => {
    if (!draggableAdsPos?.length) {
      setUserClickedSlider(false);
    }
  }, [draggableAdsPos]);

  // useEffect(() => {
  //   if (draggableContentPos?.length) {
  //     const adTime = draggableContentPos?.find(
  //       (el) => el?.page_no === contentdropDownTypeCriteria?.page?.value
  //     )?._new_publish_date;
  //     setDatePostPoner(adTime ? new Date(adTime) : new Date());
  //   }
  // }, [contentdropDownTypeCriteria?.page?.value, draggableContentPos]);

  useEffect(() => {
    if (draggableContentPos?.length) {
        const adTime = draggableContentPos?.find(
            (el) => el?.page_no === contentdropDownTypeCriteria?.page?.value
        )?._new_publish_date;
        setDatePostPoner(adTime ? new Date(adTime) : datePostPoner);
    }
}, [contentdropDownTypeCriteria?.page?.value, draggableContentPos]);


  useEffect(() => {
    // showcase / a mimic - for slider to have dummy ads in case if the ads are not present.
    if (!draggableAdsPos?.length) {
      //mimicAds
      const _arrayFillTrans = [...pageNumbersArrays]?.map((item, i) => ({
        ...adsStructure,
        page: Number(item?.value),
      }));
      setDraggableAdsPos(_arrayFillTrans);
    }
  }, [draggableAdsPos, pageIncrCount, pageNumbersArrays]);

  useEffect(() => {
    // load the pageCount in case if the ads got posted in the page after defInitialIncrCount i.e. initial count of page
    if (draggableAdsPos?.length) {
      const pageCountAmongAds = Math.max(
        ...draggableAdsPos?.map((el) => el.page_count)
      );
      setInitialIncrCount(
        pageCountAmongAds ? pageCountAmongAds : initialIncrCount
      );
    }
  }, [draggableAdsPos, initialIncrCount]);

  useEffect(() => {
    setPageIncrCount(initialIncrCount);
  }, [initialIncrCount]);

  useEffect(() => {
    const setPNoArraysByPageCount = () => {
      const fillArrObj = Array(pageIncrCount).fill()?.map((_, index) => ({ value: index + 1, label: String(index + 1) }))
      setPageNumbersArray(fillArrObj);
    };
    setPNoArraysByPageCount();
  }, [pageIncrCount]);

// console.log('draggableAdsPos .........', draggableAdsPos);

  const GRID_COLUMNS = 6; // Number of grid columns
  const GRID_WIDTH = PAPER_WIDTH / GRID_COLUMNS; // Width of each grid column

  const GRID_HALF = GRID_WIDTH/2;

  const selectedTemplateRef = useRef(dropDownTypeCriteria?.template);
  useEffect(() => {
    if (dropDownTypeCriteria?.template) {
      selectedTemplateRef.current = dropDownTypeCriteria.template;
    }
  }, [dropDownTypeCriteria?.template]);
  
  useEffect(() => {
    setContentDropDownTypeCriteria((prev) => ({
      ...prev,
      ...dropDownTypeCriteria, // Sync all values
    }));
  }, [dropDownTypeCriteria]);
  
  useEffect(() => {
    const currentContentPage = draggableContentPos?.find(
      (el) => el.page_no === dropDownTypeCriteria?.page?.value
    );
  
    if (currentContentPage) {
      setContentDropDownTypeCriteria((prev) => ({
        ...prev,
        pageNumber: {
          value: currentContentPage.page_no,
          label: `${currentContentPage.page_no} Page`,
        },
      }));
    }
  }, [draggableContentPos, dropDownTypeCriteria]);
  const handleDateChange = (e) => {
    // Call the function for ads if ads exist
    if (draggableAdsPos?.length > 0) {
      dateValueFetched(e);
    }
    // Call the function for ContentCard if content exists
    if (draggableContentPos?.length > 0) {
      contentPostponeDate(e);
    }
  };

  return (
    <div className="newsGridWrapper">
      <ModalPopUp
        show={toast}
        contentClassName={"parentModalContentJsx"}
        modalOkClick={modalOkClick}
        submitAllAdsError={submitAllAdsError}
        submitAllAdsLoading={submitAllAdsLoading}
        submitAllAdsSuccess={submitAllAdsSuccess}
      />
      <>
        <div className="thumnailModule">
          <div>
            <SliderThumb
              newsPages={pageNumbersArrays}
              dropdownCategoryValues={dropDownTypeCriteria}
              contentdropdownCategoryValues={contentdropDownTypeCriteria}
              dropDownChangeFromNewsPaperGrid={callBackDropdownchangeHandler}
              thumbNailAds={draggableAdsPos}
              thumbNailsContent={draggableContentPos} // Pass content data
              userClickDone={userClickedSlider}
              functForUserClick={selectionDoneFromSlider}
              regionSlide={regionVal}
              smTemplate={optionTemplate}
              reasignPosValues={contentgridPositionValues}
            />
          </div>
        </div>

          <div
            className="newsFrame"
            style={{ display: userClickedSlider ? "block" : "none" }}
          >
            <div className="topMenuWrap">

            <div className="sectionWrap adsDetailsWrap" style={{width: "95%"}}>
            <div className="sectionTitle">Ads Details <span style={{color:"black",fontSize: "large"}}>(Per-Page)</span></div>
            <div className="spaceDiv"
              style={{
                fontSize: "20px",
                fontWeight: "600",
                display: "flex",
                flexDirection: "row",
                gap: "20px", // Space between elements
                flexWrap: "wrap", // Wrap elements if they exceed the container's width
              }}
            >
                <span>Ad space: {spaceAllocated?.adsoccupied}%</span>
                <span>Space Left: {spaceAllocated?.contentremaining}%</span>
                <span>Ad Sqcm: {spaceAllocated?.adsoccupied_cm} cm</span>
                <span>Sqcm Left: {spaceAllocated?.remaining_cm} cm</span>
                <span>Ad  Columns: {spaceAllocated?.AdsoccupiedColumns}</span>
                <span> Columns Left: {spaceAllocated?.remainingColumns}</span>
            </div>
          </div>
          <div className="sectionWrap contentDetailsWrap" style={{width: "95%"}}>
            <div className="sectionTitle">News Details <span style={{color:"black",fontSize: "large"}}>(Per-Page)</span></div>
            <div className="spaceDiv" 
              style={{ 
                fontSize:"20px", 
                fontWeight:"600",  
                display: "flex",  
                flexDirection: "row", 
                gap: "15px",
                flexWrap: "wrap", }}>
              <span> News space: {spaceAllocated?.contentoccupied}%</span>
              <span>Space Left:{" "}{spaceAllocated?.contentremaining}%</span>
              <span>News Sqcm: {spaceAllocated?.contentoccupied_cm} cm</span>
              <span>Sqcm Left: {spaceAllocated?.contentremaining_cm} cm</span>
              <span>News Columns : {spaceAllocated?.NewsoccupiedColumns}</span>
              <span> Columns Left : {spaceAllocated?.remainingColumns}</span>
            </div>
          </div>
        {/* {console.log('draggableContentPos?.[0]?.summary_details?.[0]?', draggableContentPos)} */}
          <div className="sectionWrap summaryDetailsWrap" style={{ width: "95%" }}>
          <div className="sectionTitle">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  textAlign: "left",
                  gap: "10px", // Optional: Adds spacing between columns
                }}
              >
                <span
                  className="summaryTitle"
                  style={{
                    textAlign: "left",
                    fontSize: "20px",
                    fontWeight: "700",
                    color: "black",
                    marginBottom: "-30px",
                  }}
                >
                  {Summarydata?.[0]?.edition_name   }
                </span>
              </div>
              Summary Details
            </div>


            <div
              className="spaceDiv"
              style={{
                fontSize: "20px",
                fontWeight: "600",
                border: "2px solid black",
                padding: "10px",
              }}
            >
              <table
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  textAlign: "center",
                  border: "2px solid black", // Add solid border for the entire table
                }}
              >
                <thead>
                  <tr>
                    {/* Main Headings */}
                    <th
                      colSpan="3"
                      style={{
                        border: "2px solid black",
                        padding: "10px",
                        backgroundColor: "#f1f1f1",
                      }}
                    >
                    Percentage %
                    </th>
                    <th
                      colSpan="3"
                      style={{
                        border: "2px solid black",
                        padding: "10px",
                        backgroundColor: "#f1f1f1",
                      }}
                    >
                    Sqcm
                    </th>
                    {/* <th
                      colSpan="3"
                      style={{
                        border: "2px solid black",
                        padding: "10px",
                        backgroundColor: "#f1f1f1",
                      }}
                    >
                      Lines
                    </th> */}
                    <th
                      colSpan="3"
                      style={{
                        border: "2px solid black",
                        padding: "10px",
                        backgroundColor: "#f1f1f1",
                      }}
                    >
                      Columns
                    </th>
                  </tr>

                  {/* Subheadings */}
                  <tr>
                  <th style={{ border: "2px solid black", padding: "10px" }}>Ads</th>
                    <th style={{ border: "2px solid black", padding: "10px" }}>News</th>
                    <th style={{ border: "2px solid black", padding: "10px", color:"green" }}>Remaining</th>

                    <th style={{ border: "2px solid black", padding: "10px" }}>Ads</th>
                    <th style={{ border: "2px solid black", padding: "10px" }}>News</th>
                    <th style={{ border: "2px solid black", padding: "10px", color:"green"}}>Remaining</th>

                    {/* <th style={{ border: "2px solid black", padding: "10px" }}>Ads</th>
                    <th style={{ border: "2px solid black", padding: "10px" }}>News</th>
                    <th style={{ border: "2px solid black", padding: "10px", color:"green" }}>Remaining</th> */}

                    <th style={{ border: "2px solid black", padding: "10px" }}>Ads</th>
                    <th style={{ border: "2px solid black", padding: "10px" }}>News</th>
                    <th style={{ border: "2px solid black", padding: "10px", color:"green"}}>Remaining</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    {/* Percentages Row */}
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {((Summarydata?.[0]?.advertisement_percent * 100) || 0)} 
                      {/* {(Math.floor(Summarydata?.[0]?.advertisement_percent * 100) / 100 || 0)}  */}

                    </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                    {((Summarydata?.[0]?.editorial_percent * 100) || 0)}   
                      {/* {(Math.floor(Summarydata?.[0]?.editorial_percent * 100) / 100 || 0)}  */}

                    </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                    {((Summarydata?.[0]?.remaining_percent * 100) || 0)}                       
                      {/* {(Math.floor(Summarydata?.[0]?.remaining_percent * 100) / 100 || 0)}  */}
                    </td>

                    {/* CM Row */}
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.advertisement_no_of_cm * 100) / 100 || 0)} 
                    </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.editorial_no_of_cm * 100) / 100 || 0)} 
                    </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.total_space_cm * 100) / 100 || 0)} 
                    </td>

                    {/* Columns Row */}
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.advertisement_no_of_columns * 100) / 100 || 0)} 
                    </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.editorial_no_of_columns * 100) / 100 || 0)} 
                      </td>
                    <td style={{ border: "2px solid black", padding: "10px" }}>
                      {(Math.floor(Summarydata?.[0]?.total_space_column * 100) / 100 || 0)} 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
            
            {/* <div className="spaceDiv">
              Ad space: {spaceAllocated?.occupied}%,
              News Space:{" "}{spaceAllocated?.remaining}%,
              Ad space: {spaceAllocated?.occupied_cm} cm,
              News Space: {spaceAllocated?.remaining_cm} cm,
              Ad Lines : {spaceAllocated?.occupied_Lines},
              Lines Left : {spaceAllocated?.remaining_Lines}
            </div> */}
            <div className="dropAndBtn">
              <div className="dropWrap">
                <div className="dropTitle">Bookmark page: </div>
                <div className="templateDrop editionTypeDrop bookmark">
                  <Select
                    options={bookMarkOption}
                    value={bookMarkValue}
                    onChange={(e) => bookMarkChange(e, "bookMark")}
                  />
                </div>
                <div className="templateDrop editionTypeDrop">
                  <Select
                    options={pageNumbersArrays}
                    value={dropDownTypeCriteria?.page || contentdropDownTypeCriteria?.page}
                    // value1={contentdropDownTypeCriteria?.page}

                    onChange={(e) => dropDownOnChangeEventhandler(e, "page")}
                  />
                </div>
              </div>
              <div className="dropWrap">
                <div className="dropTitle">Page Count: </div>
                <PageCounter
                  pageCountIncrDecrHandle={pageCountIncrDecrHandle}
                  pageIncrCount={pageIncrCount}
                />
              </div>
              <div className="dropWrap">
                <div className="dropTitle">Edition Type: </div>
                <div className="templateDrop editionTypeDrop">
                  <Select
                    options={editionTypeOption}
                    value={dropDownTypeCriteria?.edition}
                    onChange={(e) => dropDownOnChangeEventhandler(e, "edition")}
                  />
                </div>
              </div>
            </div>
            <div className="dropAndBtn">
              <div className="dropWrap">
                <div className="dropTitle">Paper Number: </div>
                <div className="templateDrop paperNumberDrop">
                  <Select
                    options={paperNumberOption}
                    value={dropDownTypeCriteria?.paperNumber}
                    value1={contentdropDownTypeCriteria?.paperNumber}

                    onChange={(e) =>
                      dropDownOnChangeEventhandler(e, "paperNumber")
                    }
                    isDisabled={
                      dropDownTypeCriteria?.edition?.value === "single"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {
                <div className="dropWrap">
                  <div className="dropTitle">Template: </div>
                  <div className="templateDrop">
                    <Select
                      options={optionTemplate}
                      value={dropDownTypeCriteria?.template}
                      onChange={(e) =>
                        dropDownOnChangeEventhandler(e, "template")
                      }
                    />
                  </div>
                </div>
              }
              <div className="dropWrap">
                <div className="dropTitle">Postpone Date: </div>
                <div className="templateDrop">
                  <CalenderPick
                    id={"advertisementDate"}
                    date={datePostPoner}
                    // changeDate={dateValueFetched}
                    changeDate={
                      handleDateChange
                    }   
                  />
                </div>
              </div>

              <div className="btnSubmit">
                <Button
                  // disabled={
                  //   mimicAdsFunc(draggableAdsPos , draggableContentPos) || submitAllAdsLoading
                    
                  // }
                  onClick={submitActionDraggablePost}
                >
                  Submit Ads
                </Button>
              </div>
              <div className="pdfDownLoad" title="Save/print page">
                <PrintContent
                  fileName={`Schedule__${new Date().toLocaleDateString()}__PageNO:${dropDownTypeCriteria?.page?.value
                    }`}
                  refContainer={parentRef?.current}
                  pageOptions={pageStyleOption}
                />
              </div>
            </div>
          </div>

          <div
            ref={parentRef}
            className="parent"
            id="parent"
            style={{
              position: "relative",
              width: `${PAPER_WIDTH}px`, // Adjust the width and height as needed
              height: `${PAPER_HEIGHT}px`,
            }}
          >

            {/* <div className="templateDesign">
              {dropDownTypeCriteria?.template?.template_image && (
                <img
                  src={`data:image/png;base64,${dropDownTypeCriteria?.template?.template_image}`}
                  alt={dropDownTypeCriteria?.template?.template_name}
                ></img>
              )}
              {dropDownTypeCriteria?.template?.template_default_all && (
                <div className="pageNoLocation">
                  <div className="regn">{regionVal?.label}</div>
                  <div>{dropDownTypeCriteria?.page?.label}</div>
                </div>
              )}
            </div> */}
            {/* {console.log('selectedTemplateRef', selectedTemplateRef?.current)} */}
            <div className="templateDesign">
              {dropDownTypeCriteria?.template?.template_image ? (
                <img
                  src={`data:image/png;base64,${dropDownTypeCriteria?.template?.template_image}`}
                  alt={dropDownTypeCriteria?.template?.template_name}
                />
              ) : (
                <img
                  src={`data:image/png;base64,${selectedTemplateRef.current?.template_image}`}
                  alt={selectedTemplateRef.current?.template_name}
                />
              )}
              {dropDownTypeCriteria?.template?.template_default_all && (
                <div className="pageNoLocation">
                  <div className="regn">{regionVal?.label}</div>
                  <div>{dropDownTypeCriteria?.page?.label}</div>
                </div>
              )}
            </div>



            {bookMarkValue?.value && (
              <div
                className="bookMarkOverPage"
                style={{
                  display: bookMarkValue?.value === "none" ? "none" : "block",
                }}
              >
                <p>{bookMarkValue?.label}</p>
              </div>
            )}


             {/* Grid Overlay */}
             <div
                className="gridOverlay"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "grid",
                  gridTemplateColumns: `repeat(${GRID_COLUMNS}, 1fr)`,
                  pointerEvents: "none",
                }}
              >
                {Array.from({ length: GRID_COLUMNS - 1 }).map((_, index) => (
                  <div
                    key={index}
                    style={{
                      borderRight: "1px dotted blue",
                      height: "100%",
                    }}
                  ></div>
                ))}
              </div>

            {console.log('adsInsideNewsPaper draggableAdsPos', draggableAdsPos)}
            {adsInsideNewsPaper(draggableAdsPos)?.map(

              (el, i) =>
                 (
                  <DraggableCard
                    childValues={el}
                    key={i}
                    reasignPosValues={gridPositionValues}
                    deleteChildDivs={deleteTheChildrenInDrags}
                    afterPageMovedArrUpdate={actionAdMovedToOtherPage}
                    pageNumbersArrays={pageNumbersArrays}
                  />
                )
             )}
            {/* {console.log('draggableContentPos:', draggableContentPos)} */}

            {contentInsideNewsPaper(draggableContentPos)?.map((el, i) => (
              <ContentCard
                childValues={{
                  ...el,
                  gridX: el?.publish_details?.[0]?.content_position_x || 0,
                  gridY: el?.publish_details?.[0]?.content_position_y || 0,
                }}
                key={el.id || i}
                reasignPosValues={contentgridPositionValues}
                deleteChildDivs={contentcardDelete}
                afterPageMovedArrUpdate={contentactionMovedToOtherPage}
                pageNumbersArrays={pageNumbersArrays}
              />
            ))}

          </div>
        </div>
      </>
    </div>
  );
};

export default memo(NewsPaperContentGrid);