import * as type from '../Constants/adlist';

const initialState ={
    fetchInitialParamLoading : false,
    fetchMetaDataInitalDataParams : [],
    errorInFetchMetaData: null,

    regionDatas: [],
    regionDataLoading: false,
    regionDataError: null,
    
    fetchNewsLoading: false,
    newsData: [],
    error: null,
}

export default function fetchinitialmetadata(state = initialState, action){
    switch(action.type){
        case type.FETCH_INITIAL_PARAMS_DATA: 
            return {
                ...state,
                fetchInitialParamLoading: true
            }
        case type.FETCH_INITIAL_PARAMS_DATA_SUCESS:
            return {
                ...state,
                fetchInitialParamLoading: false,
                fetchMetaDataInitalDataParams: action?.fetchedParamData
            }

        case type.FETCH_INITIAL_PARAMS_DATA_FAILED:
            return {
                ...state,
                fetchInitialParamLoading: false,
                fetchMetaDataInitalDataParams : [],
                errorInFetchMetaData: action?.erorInfetchedParamData
            }

        case type.FETCH_REGIONS:
            return {
                regionDatas: [],
                regionDataLoading: true,
                regionDataError: null,
            }
        case type.FETCH_REGIONS_SUCCESS:
            return {

                regionDatas: action?.regionDatasFromApi,
                regionDataLoading: false,
                regionDataError: null,

            }
        case type.FETCH_ADS_BY_POST_FAILED:
            return {

                regionDatas: [],
                regionDataLoading: false,
                regionDataError: action?.regionDataErrorFromApi,

            }
        default:
            return state; 
    }

}