// const GATEWAY_URL = `https://esanchaya.com/`;
// const GATEWAY_URL = `https://testing.esanchaya.com/`;
const GATEWAY_URL = `https://preprod.esanchaya.com`;
// const GATEWAY_URL = `http://10.100.13.138:8070/`;
// const GATEWAY_URL = `http://localhost:22085`;
export const fetchFilterParams = `${GATEWAY_URL}/my_services_api/scheduling/filter_params`;
export const fetchAdsListAll =  `${GATEWAY_URL}/my_services_api/scheduling/fetch_dummy_chart`;
export const submitAds = `${GATEWAY_URL}/my_services_api/scheduling/post_dummy_chart`;
export const fetchRegionUrl = `${GATEWAY_URL}/my_services_api/scheduling/get_regions`;
export const loginApi = `${GATEWAY_URL}/session/auth/login`;
export const editSlugNoUrl = `${GATEWAY_URL}/my_services_api/scheduling/update_slug_number`;
export const editorialDetails = `${GATEWAY_URL}/my_services_api/scheduling/fetch_editorial`;
export const submitEditorials = `${GATEWAY_URL}/my_services_api/scheduling/post_editorial_details`;
export const deleteContentUrl = `${GATEWAY_URL}/my_services_api/scheduling/delete_content_data`;
export const getSummaryData = `${GATEWAY_URL}/my_services_api/scheduling/get_summary_data`;


// console.log("edit...............................",editorialDetails);





