import {
    POST_EDITORIAL_DETAILS_REQUEST,
    POST_EDITORIAL_DETAILS_SUCCESS,
    POST_EDITORIAL_DETAILS_FAILURE,
  } from "../Constants/adlist"; // Correct path to adlist.js
  
  
  const initialState = {
    editorialDetails: [], 
    loading: false,
    success: false,
    error: null,
  };
  
  const editorialReducer = (state = initialState, action) => {
    switch (action.type) {
      case POST_EDITORIAL_DETAILS_REQUEST:
        return {
          ...state,
          editorialDetails: [], // Clear content on submit
          loading: true,
          success: false,
          error: null,
        };
      case POST_EDITORIAL_DETAILS_SUCCESS:
        return {
          ...state,
          editorialDetails: action.payload || [], // Update with new data (if available)
          loading: false,
          success: true,
          error: null,
        };
      case POST_EDITORIAL_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default editorialReducer;
  