import * as type from '../Constants/adlist';  // Import action types

const initialState = {
  data: [],
  loading: false,
  error:null,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.FETCH_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case type.FETCH_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case type.FETCH_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
      case type.RESET_DATA:
    return { ...state, data: [] };

    case type.POST_EDITORIAL_DETAILS_REQUEST:
      return { ...state, loading: true, error: null };
    case type.POST_EDITORIAL_DETAILS_SUCCESS:
      return { ...state, loading: false }; // Turn off loading
    case type.POST_EDITORIAL_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload }; // Capture error
    default:
      return state;
  }
};

export default dataReducer;