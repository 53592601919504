import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService"; 
import * as apiUrl from '../apiUrl';
import { POST_EDITORIAL_DETAILS_REQUEST,  RESET_DATA  } from "../../ReduxState/Constants/adlist"; 
import { postEditorialDetailsSuccess, postEditorialDetailsFailure } from '../../ReduxState/Actions/actionAd';

// API function
async function postEditorialDetailsAPI(adsArrayList) {
  const formattedLoad = { editorial_details: adsArrayList };
  try {
    const response = await httpService.postCallMethod(apiUrl.submitEditorials, formattedLoad);
    return response;
  } catch (error) {
    throw error;
  }
}

// Worker saga
function* postEditorialDetailsSaga(action) {
  try {
    const response = yield call(postEditorialDetailsAPI, action.payload);
    yield put(postEditorialDetailsSuccess(response));
    yield put({ type: RESET_DATA });
  } catch (error) {
    yield put(postEditorialDetailsFailure(error.message || "Failed to post editorial details"));
  }
}

// Watcher saga
export function* watchPostEditorialDetails() {
  yield takeEvery(POST_EDITORIAL_DETAILS_REQUEST, postEditorialDetailsSaga);
}

export default watchPostEditorialDetails;
