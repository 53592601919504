import { useState, useRef, memo } from "react";
import Select from "react-select";
import Draggable from "react-draggable";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiArrowRightCircle } from "react-icons/hi2";
import TooltipAd from "../TooltipAd/TooltipAd";
import { splitDimensionStr, truncateElipseText } from "../../Utils/Utils";

const DraggableCard = ({
  childValues,
  reasignPosValues,
  deleteChildDivs,
  afterPageMovedArrUpdate,
  pageNumbersArrays,
}) => {
  const [displayPageMove, setDisplayPageMove] = useState(false);
  const [pageMovedValue, setPageMovedValue] = useState({});
  const refDrags = useRef(null);

  const handleDrag = (e, data, elName) => {
    reasignPosValues({
      name: elName,
      x: data.x,
      y: data.y,
    });
  };

  // console.log('DraggableCard childValues :', childValues)

  const selectPageOptChange = (e, childAd) => {
    setPageMovedValue(e);
    setDisplayPageMove((prev) => !prev);
    const updata_temp = { ...childAd, page: e?.value };
    afterPageMovedArrUpdate(updata_temp);
  };

  const activePageOpt = (e) => {
    e.preventDefault();
    setDisplayPageMove((prev) => !prev);
  };

  const adUnderSize = (adSize = "5x3") => {
    const [widthStr, heightStr] = splitDimensionStr(adSize);
    const UNDER_SIZE = 5;
    const width = Number(widthStr);
    const height = Number(heightStr);
    const underWidth = width <= UNDER_SIZE;
    const underHeight = height <= UNDER_SIZE;
    const checkCondn = underWidth || underHeight;
    return checkCondn;
  };

  const contentList = (ads) => {
    // console.log('ads :', ads)
    const AGENT_CUST_NAME_LENGTH_EXCEED = 20;
    const SHOW_AGENT_CUST_NAME_LENGTH_EXCEED = 5;
    return (
      <div>
        <p>{ads?.cio_number }</p>
        <p>{ads?.size }</p>
        <p> {ads?.client_name} </p>
        <p>  {ads?.caption} </p>
        <p>{ ads?.as_on_date } </p>
        <p>{ads?.slug_number }</p>
        <p>{ads?.amount_status }</p>
       
      </div>
    );
  };

  const parentOffset = refDrags.current?.offsetParent?.getBoundingClientRect() || { left: 0, top: 0 };
const adjustedX = childValues.gridX - parentOffset.left;
const adjustedY = childValues.gridY - parentOffset.top;


  return (
    <Draggable
      bounds="parent"
      // defaultPosition={{
      //   x: childValues?.gridX,
      //   y: childValues?.gridY,
      // }}
      position={{
        x: childValues?.gridX,
        y: childValues?.gridY,
      }}
      onStop={(e, data) => handleDrag(e, data, childValues.id)}
      nodeRef={refDrags}
    >
      <div
        id={childValues.id}
        style={{
          position: "absolute",
          backgroundColor: "#cdebf9",
          border: "1px solid #78b7d3",
          width: childValues?.width + "px",
          height: childValues?.height + "px",
          cursor: "pointer",
        }}
        className="draggableCard"
        ref={refDrags}
      >
        <div
          className={`dragCard-innerContent `}
        >
          <span
            title="Change page"
            className="moveAdArrow"
            onClick={activePageOpt}
          >
            <HiArrowRightCircle size={22} color="black" />
          </span>
          {displayPageMove && (
            <span className="moverAdPageOpt">
              <Select
                options={pageNumbersArrays}
                value={pageMovedValue}
                onChange={(e) => selectPageOptChange(e, childValues)}
              />
            </span>
          )}

          {adUnderSize(childValues?.size) ? (
            <TooltipAd renderComponent={contentList(childValues)} />
          ) : (
            contentList(childValues)
          )}
        </div>
        <span
          title="remove"
          className="delChildDiv"
          onClick={(e) => deleteChildDivs(e, childValues)}
        >
          <AiFillCloseCircle size={20} color="black" />
        </span>
      </div>
    </Draggable>
  );
};

export default memo(DraggableCard);
